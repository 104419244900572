<style scoped>
/* Ensure the container does not overflow */
.container {
	overflow: hidden;
}
</style>

<template>
	<div class="container mx-auto px-4">
		<h1 class="text-xl font-bold my-4">Hello {{ userName }},</h1>
		<div v-if="hasData" class="join join-vertical w-full">
			<div v-for="item in sortedData" :key="item.created_at" class="collapse join-item">
				<input type="checkbox" :id="'item-' + item.created_at" class="peer" />
				<label :for="'item-' + item.created_at" class="collapse-title text-xl font-medium cursor-pointer">
					<div class="flex justify-between items-center">
						<div class="flex flex-col">
							<span class="text-xs uppercase">{{ formatDateTime(item.created_at) }}</span>
							<span class="text-md" ref="foodName" :id="'foodName-' + item.created_at">
								{{ getFoodNames(item.data) }}
							</span>
						</div>
						<div class="flex flex-col items-end">
							<span class="text-l">Total Calories</span>
							<span class="text-l font-bold">{{ totalCalories(item.data) }} calories</span>
						</div>
					</div>
				</label>
				<hr class="border-gray-500 mx-4" />
				<div class="collapse-content p-2 rounded-lg shadow-lg mt-2">
					<div v-for="data in item.data" :key="data.food_name" class="mb-4">
						<h3 class="font-bold">{{ data.food_name }}</h3>
						<p><strong>Quantity:</strong> {{ data.food_quantity }}</p>
						<p><strong>Calories:</strong> {{ data.food_calories }} grams</p>
						<p><strong>Fat:</strong> {{ data.fat_grams }} grams</p>
						<p><strong>Carbs:</strong> {{ data.carbs_grams }} grams</p>
						<p><strong>Protein:</strong> {{ data.protein_grams }} grams</p>
						<p><strong>Grade:</strong> {{ data.food_grade }}</p>
					</div>
				</div>
			</div>
		</div>
		<p v-else class="text-center text-lg py-4">No data available</p>
	</div>
</template>

<script>
import ApiService from '@/services/ApiService';

export default {
	data() {
		return {
			userData: [],
			userName: '',
			startKey: '', // Initialize startKey
		};
	},
	computed: {
		sortedData() {
			return this.hasData ? [...this.userData].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) : [];
		},
		hasData() {
			return Array.isArray(this.userData) && this.userData.length > 0;
		},
	},
	methods: {
		formatDateTime(value) {
			return new Date(value).toLocaleString();
		},
		totalCalories(data) {
			return data.reduce((total, item) => total + item.food_calories, 0);
		},
		fetchUserData() {
			ApiService.getUserDetails(this.startKey)
				.then((response) => {
					console.log(response.data);
					this.userData = response.data.userData.map((item) => ({
						...item,
						expanded: false, // Add an expanded property for toggling
						created_at: new Date(item.created_at).toISOString(), // Ensure created_at is a valid date string
					}));
					this.userName = response.data.userName ?? 'User';
				})
				.catch((error) => {
					console.error('There was an error fetching the data: ', error);
				});
		},
		toggle(item) {
			item.expanded = !item.expanded;
		},
		getFoodNames(data) {
			const names = data.map((d) => `${d.food_name} (${d.food_quantity})`);
			const container = this.$refs.foodName;
			if (container) {
				let displayedNames = names.join(', ');
				let width = container[0].offsetWidth;
				let maxLength = width / 10; // approximate character width
				if (displayedNames.length > maxLength) {
					displayedNames = displayedNames.slice(0, maxLength - 3) + '...';
				}
				return displayedNames;
			}
			return names.join(', ');
		},
	},
	mounted() {
		this.startKey = this.$route.params.startKey;
		console.log('Received startKey:', this.startKey); // Check what you receive here
		this.fetchUserData();
	},
	watch: {
		'$route.params.startKey': {
			handler(newKey) {
				this.startKey = newKey;
				console.log('Updated startKey:', newKey);
				this.fetchUserData();
			},
			immediate: true,
		},
	},
};
</script>
