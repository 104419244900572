<template>
  <div id="app">
    <user-dashboard></user-dashboard>
  </div>
</template>

<script>import UserDashboard from './components/UserDashboard.vue';

export default {
  name: 'SpeakTrack.Ai',
  components: {
    UserDashboard
  },
  mounted() {
  }
}
</script>

<style>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
</style>
