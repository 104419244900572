import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import '@fortawesome/fontawesome-free/css/all.css';
import "toastify-js/src/toastify.css";
import '@vueform/multiselect/themes/default.css';

const app = createApp(App);
// Use the router
app.use(router);

app.mount('#app');
