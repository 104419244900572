import axios from 'axios';

class ApiService {
	constructor() {
		this.isProd = process.env.NODE_ENV === 'production';
		const baseURL = this.isProd ? 'https://speak-track-uvdqjpj2tq-uc.a.run.app' : 'https://a360-106-213-60-64.ngrok-free.app';
		this.http = axios.create({
			baseURL: baseURL,
			headers: {
				'Content-Type': 'application/json',
			},
		});
		// request interceptor to test and validate REST calls. ONLY FOR TESTING
		// this.http.interceptors.request.use(
		// 	function (config) {
		// 		console.log('Request sent: ', JSON.stringify(config, null, 2));
		// 		return config;
		// 	},
		// 	function (error) {
		// 		return Promise.reject(error);
		// 	},
		// );
	}

	getUserDetails(startKey) {
		return this.http.post('/dashboard', {
			startKey: startKey,
		});
	}
}

export default new ApiService();
